/*   */
// All variables can be found here
//https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import "~antd/lib/style/color/colorPalette.less";
@import "~antd/dist/antd.less";
@import "~antd/lib/style/themes/default.less";

body,
html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;

  font-family: "Poppins", "Roboto", "Helvetica" !important;
}

// -------- Colors -----------
@primary-color: #252525;
@link-color: #e5b02c;
@primary-border-color: #bcbcbc;
@primary-text-color: #575757;
@white-color: #ffffff;
// Fonts
@description-font-size: 14px;
@default-fontsize: 16px;
@noble-regular-font-size: 18px;

//Menu
@menu-item-color: @primary-text-color;
@menu-highlight-color: @primary-color;
@menu-item-font-size: @noble-regular-font-size;
@menu-icon-size: @noble-regular-font-size;
@menu-item-group-title-font-size: @noble-regular-font-size;

//Switch
@switch-color: #8fd89f;

//Radio button group
@ant-radio-button-checked: @primary-color; // Tooltip
// ---
// Tooltip max width
@tooltip-max-width: 250px;
// Tooltip text color
@tooltip-color: #fff;
// Tooltip background color
@tooltip-bg: @primary-color;
// Tooltip arrow width

@badge-dot-size: 14px;
@table-selected-row-bg: #f5f5f5f5 !important;
// @popover-bg: transparent;
// // Popover arrow width
// @popover-arrow-width: 0px;
// // Popover arrow color
// @popover-arrow-color: transparent;
// // Popover outer arrow color
// @popover-arrow-outer-color: transparent;
// // Popover distance with trigger
// @popover-distance: 0px;
@popover-padding-horizontal: 0px;

@primary-color: #252525;@link-color: #e5b02c;@primary-border-color: #bcbcbc;@primary-text-color: #575757;@white-color: #ffffff;@description-font-size: 14px;@default-fontsize: 16px;@noble-regular-font-size: 18px;@menu-item-color: @primary-text-color;@menu-highlight-color: @primary-color;@menu-item-font-size: @noble-regular-font-size;@menu-icon-size: @noble-regular-font-size;@menu-item-group-title-font-size: @noble-regular-font-size;@switch-color: #8fd89f;@ant-radio-button-checked: @primary-color;@tooltip-max-width: 250px;@tooltip-color: #fff;@tooltip-bg: @primary-color;@badge-dot-size: 14px;@table-selected-row-bg: #f5f5f5f5 !important;@popover-padding-horizontal: 0px;